import React from "react";
import { Box, Chip, Tooltip } from "@mui/material";

import { FlightTakeoff, Satellite, ThreeDRotation } from "@mui/icons-material";
import { useStyles } from "../styles";
import { MediaType } from "../../../openapi/api";

interface TopFiltersProps {
  selectedMediaDataType: string[];
  toggleSelectedMediaDataType: (type: MediaType) => void;
}

export const TopFilters = ({
  selectedMediaDataType,
  toggleSelectedMediaDataType,
}: TopFiltersProps) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.topControl}>
      <Tooltip
        title="Select filter to filter file by DroneImage type"
        placement="bottom"
      >
        <Chip
          label="Drone Images"
          onClick={() => toggleSelectedMediaDataType(MediaType.DroneImage)}
          color={
            selectedMediaDataType.includes(MediaType.DroneImage)
              ? "error"
              : "secondary"
          }
          icon={<FlightTakeoff fontSize="small" />}
        />
      </Tooltip>
      <Tooltip
        title="Select filter to filter file by Satellite Images type"
        placement="bottom"
      >
        <Chip
          label="Satellite Images"
          onClick={() => toggleSelectedMediaDataType(MediaType.PhoneImage)}
          color={
            selectedMediaDataType.includes(MediaType.PhoneImage)
              ? "error"
              : "secondary"
          }
          icon={<Satellite fontSize="small" />}
        />
      </Tooltip>
      <Tooltip
        title="Select filter to filter file by Point Cloud type"
        placement="bottom"
      >
        <Chip
          label="Point Clouds"
          onClick={() => toggleSelectedMediaDataType(MediaType.PointCloud)}
          color={
            selectedMediaDataType.includes(MediaType.PointCloud)
              ? "error"
              : "secondary"
          }
          icon={<ThreeDRotation fontSize="small" />}
        />
      </Tooltip>
    </Box>
  );
};
