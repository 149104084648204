export const IMAGE_CDN = "https://theme.ipen.eon.com";
export const ICON_CDN = "https://icons.ipen.eon.com";

export const CDN_IMAGES = {
  icons: (imageName: string) => getCDNImage(`${IMAGE_CDN}/icons/`, imageName),
  logos: (imageName: string) => getCDNImage(`${IMAGE_CDN}/logos/`, imageName),
  images: (imageName: string) => getCDNImage(`${IMAGE_CDN}/images/`, imageName),
};

export const getCDNImage = (prefix = "", imageName: string) => {
  return `${prefix}${imageName}`;
};
