import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";

import clsx from "clsx";
import { Viewer, ImageryLayer } from "resium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import { Box } from "@mui/material";
import * as Cesium from "cesium";

import { calculateBoundingBox } from "../../util/coordinates";
import { useApi } from "../../hooks/useApi";
import { FileGeoJsonComponent } from "./GeoJsonComponent/FileGeoJsonComponent";
import { DatasetGeoJsonComponent } from "./GeoJsonComponent/DatasetGeoJsonComponent";
import { PylonGeoJsonComponent } from "./GeoJsonComponent/PylonGeoJsonComponent";
import { LocationGeoJsonComponent } from "./GeoJsonComponent/LocationGeoJsonComponent";
import { useGridApi } from "../../hooks/useGridApi";
import {
  RightSideControls,
  areaOfInterestLogic,
  getViewerViewArea,
} from "./Components/RightSideControls";
import { InputSearch } from "./Components/InputSearch";
import { Results } from "./Components/Results";
import { TopFilters } from "./Components/TopFilters";
import { ResultsWindow } from "./Components/ResultsWindow";
import { MapSwitcher } from "./Components/MapSwitcher";

import {
  ApiHvPylonResponse,
  HvPylon,
  paths as gridPaths,
} from "../../types/grid";
import getWebGLStub from "../../__mocks__/cesiumjs-webgl-stub";
import { isTestingWithJest } from "../../util/testing";
import { useStyles } from "./styles";
import dayjs, { Dayjs } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import {
  Dataset,
  DatasetPage,
  FileDetail,
  FilePage,
  MediaType,
  Solution,
  SolutionPage,
} from "../../openapi/api";
import { AxiosResponse } from "axios";
import { masterRecordLimit } from "../../util/utils";

// extend dayjs
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

// type
declare global {
  interface Window {
    CESIUM_BASE_URL: string;
  }
}

export enum SEARCH_TYPE {
  PYLON = "PYLON",
  DATASET = "DATASET",
}

export enum ZOOM_TYPE {
  NO_OFFSET = "NO_OFFSET",
  SINGLE_OFFSET = "SINGLE_OFFSET",
  DOUBLE_OFFSET = "DOUBLE_OFFSET",
}

export enum RIGHT_SIDE_SEARCH_BUTTON_ID {
  // dataset
  datasetAtPointSearch = "datasetAtPointSearch",
  datasetInView = "datasetInView",
  datasetViaAreaOfInterest = "datasetViaAreaOfInterest",
  // file
  fileInView = "fileInView",
  fileViaAreaOfInterest = "fileViaAreaOfInterest",
  // location based search
  locateMe = "locateMe",
  userLocationBasedSearch = "userLocationBasedSearch",
}

export type DateRange<T> = [T | null, T | null];

export interface SearchConfig {
  endpoint?: string;
  _resource?: string;
  searchTitleText?: string;
  focusOnResults?: boolean;
  next?: string;
}

// default data response data
Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
  0,
  0,
  0,
  89,
);
Cesium.Camera.DEFAULT_VIEW_FACTOR = 1.5;

type GlobExtended = Cesium.Globe & {
  _surface: { _tilesToRender: { _level: number }[] };
};

// set assets URL
window.CESIUM_BASE_URL = "/cesium";

export const EntityGraphicsState = {
  // location
  LOCATION_Red_Point_Graphics: {
    color: Cesium.Color.RED.withAlpha(0.7),
    pixelSize: 20,
    outlineColor: Cesium.Color.BLACK,
    outlineWidth: 2,
  },

  // file
  NORMAL_file_Point_Graphics: {
    color: Cesium.Color.CYAN.withAlpha(0.7),
    pixelSize: 20,
    outlineColor: Cesium.Color.BLACK,
    outlineWidth: 2,
  },
  HOVER_file_Point_Graphics: {
    color: Cesium.Color.BLUE,
    pixelSize: 21,
  },
  NORMAL_file_Polygon_graphics: {
    height: 0,
    material: Cesium.Color.GREEN.withAlpha(0.3),
    outlineColor: Cesium.Color.GREEN,
    outlineWidth: 1,
    outline: true,
  },
  HOVER_file_Polygon_graphics: {
    material: Cesium.Color.BLUE.withAlpha(0.3),
    outlineColor: Cesium.Color.GREEN,
  },

  // file 3D
  NORMAL_file_Ellipsoid_Graphics: {
    radii: new Cesium.Cartesian3(1.0, 1.0, 1.5),
    material: Cesium.Color.RED,
  },
  HOVER_file_Ellipsoid_Graphics: {
    radii: new Cesium.Cartesian3(1.2, 1.2, 1.8),
    material: Cesium.Color.BLUE,
  },

  // dataset
  NORMAL_dataset_Polygon_graphics: {
    height: 0,
    material: Cesium.Color.RED.withAlpha(0.3),
    outlineColor: Cesium.Color.BLACK,
    outlineWidth: 1,
    outline: true,
  },
  HOVER_dataset_Polygon_graphics: {
    material: Cesium.Color.YELLOW.withAlpha(0.6),
    outlineColor: Cesium.Color.YELLOW,
  },
};

// IT SEEMS THIS IS NOT FREE WE NEED TO PROVIDE TOKEN
// const defaultBaseLayer = Cesium.ImageryLayer.fromProviderAsync(
//   Cesium.ArcGisMapServerImageryProvider.fromBasemapType(
//     Cesium.ArcGisBaseMapType.SATELLITE,
//     { enablePickFeatures: false },
//   ),
//   {},
// );

let rollbackStyleLastHoverEntityFn = () => {};
const imageDetailsCache = {};

// dataset names: 'DSGVO_2021-06-25_HlO_Mit', 'DSGVO_Line1', 'DSGVO_Pole1', 'DSGVO_Pole2', 'DSGVO_Pole3_2', 'DSGVO_Pole3_1', 'DSGVO_Pole4_1_LightRoom_Error'
// pylon names: 'Lorem Hic', 'placeat', 'quas sit', 'culpa!', 'Lorem sit', 'magnam,', 'amet esse', 'dolor', 'nobis sit', 'magnam,', 'odit sit', 'odit'
export const MDPViewer = () => {
  const { classes } = useStyles();
  const { DatasetApi, MasterDataApi, FileApi } = useApi();
  const { gridApiCall } = useGridApi();
  const [contextOptions, _] = useState(
    isTestingWithJest()
      ? {
          getWebGLStub,
        }
      : {},
  );

  // ref
  const isLoaded = useRef<boolean>(false);
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);
  const viewerRef = useRef<Cesium.Viewer | null>(null); // created ref to Viewer
  const userSelectingRef = useRef<boolean>(false);

  // map state
  const [tileZoomLevel, setTileZoomLevel] = useState(1);
  const [currentViewPortPolygon, setCurrentViewPortPolygon] =
    useState<string>("");
  const [isBaseLayer, setIsBaseLayer] = useState<boolean>(true);
  const [mapProjection] = useState(new Cesium.WebMercatorProjection());
  // const [defaultBaseLayer] = useState(
  //   new Cesium.ImageryLayer(
  //     new Cesium.OpenStreetMapImageryProvider({
  //       url: "https://tile.openstreetmap.de/",
  //     }),
  //     {},
  //   ),
  // );

  const [terrain] = useState<Cesium.Terrain>(
    new Cesium.Terrain(
      Cesium.CesiumTerrainProvider.fromUrl(
        //https://api.maptiler.com/tiles/terrain-quantized-mesh-v2/{z}/{x}/{y}.quantized-mesh-1.0?key=Qtb0lXfaWFX2yQbdXFm3
        `https://api.maptiler.com/tiles/terrain-quantized-mesh-v2/?key=${window.REACT_APP_MAPTILER_KEY}`,
        {
          requestVertexNormals: true,
        },
      ),
    ),
  );

  const [topoTiles] = useState<Cesium.ImageryLayer>(
    new Cesium.ImageryLayer(
      new Cesium.UrlTemplateImageryProvider({
        url: `https://api.maptiler.com/maps/topo-v2/{z}/{x}/{y}.png?key=${window.REACT_APP_MAPTILER_KEY}`,
        minimumLevel: 0,
        maximumLevel: 20,
        tileWidth: 512,
        tileHeight: 512,
        credit: new Cesium.Credit(
          `<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>`,
          true,
        ),
      }),
    ),
  );
  const [satelliteTiles] = useState<Cesium.ImageryLayer>(
    new Cesium.ImageryLayer(
      new Cesium.UrlTemplateImageryProvider({
        url: `https://api.maptiler.com/maps/9ddfd80d-2ee9-4649-aa25-c6a2f003e2f2/{z}/{x}/{y}.jpg?key=${window.REACT_APP_MAPTILER_KEY}`,
        minimumLevel: 0,
        maximumLevel: 20,
        tileWidth: 512,
        tileHeight: 512,
        credit: new Cesium.Credit(
          `<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>`,
          true,
        ),
      }),
    ),
  );

  // settings
  const [is3D, setIs3D] = useState<boolean>(false);
  const [hoverId, setHoverId] = useState<string | null>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [hoverDatasetId, setHoverDatasetId] = useState<string | null>(null);
  const [myLocation, setMyLocation] = useState<GeoJSON.Point | null>(null);

  //state
  const [searchText, setSearchText] = useState<string>("");
  const [searchType, setSearchType] = useState<SEARCH_TYPE>(SEARCH_TYPE.PYLON);
  const isPylonSearch = useMemo(
    () => searchType === SEARCH_TYPE.PYLON,
    [searchType],
  );

  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [resultLoading, setResultLoading] = useState<boolean>(false);
  const [searchButtonLoading, setSearchButtonLoading] = useState("");
  const [searchHelperText, setSearchHelperText] = useState<string>("");

  const [searchButtonId, setSearchButtonId] = useState("");
  const [solutions, setSolutions] = useState<SolutionPage>({} as SolutionPage);
  const [selectedImageDetails, setSelectedImageDetails] =
    useState<FileDetail | null>(null);

  // filter state
  const [dateRangeDataset, setDateRangeDataset] = React.useState<
    DateRange<Dayjs>
  >([null, null]);
  const [dateRangeFile, setDateRangeFile] = React.useState<DateRange<Dayjs>>([
    null,
    null,
  ]);
  const [selectedMediaDataType, setSelectedMediaDataType] = useState<
    MediaType[]
  >([]); // DroneImage","SatelliteImage","PointCloud"
  const [selectedFileState, setSelectedFileState] = useState<string | null>(
    null,
  );
  const [selectedDatasetState, setSelectedDatasetState] = useState<
    string | null
  >(null);

  // dataset
  const [datasetResponse, setDatasetResponse] = useState<DatasetPage | null>(
    null,
  );

  const [clickedDataset, setClickedDataset] = useState<Dataset | null>(null);
  const [selectedSolutionDataset, setSelectedSolutionDataset] =
    useState<Solution | null>(null);
  const [selectedSolutionFile, setSelectedSolutionFile] =
    useState<Solution | null>(null);
  const [rightSideFilter, setRightSideFilter] = useState<
    Record<string, string>[]
  >([]);

  // pylons
  const pylonReq = useRef<NodeJS.Timeout | null>(null);
  const [initialPylonList, setInitialPylonList] = useState<ApiHvPylonResponse>(
    {},
  );
  const [pylonRespData, setPylonRespData] = useState<ApiHvPylonResponse>({});
  const [selectedPylon, setSelectedPylon] = useState<HvPylon | null>(null);

  // results
  const [respData, setRespData] = useState<FilePage | null>(null);

  useEffect(() => {
    if (!pylonRespData?.data) {
      setSelectedPylon(null);
    }
  }, [pylonRespData]);

  useEffect(() => {
    if (isPylonSearch && !selectedPylon) {
      resetSearch();
    }
  }, [isPylonSearch, selectedPylon]);

  const gridApiCallWithResetState = useCallback(
    <Path,>(args) => {
      resetStateData();
      return gridApiCall<Path>(args);
    },
    [gridApiCall],
  );

  const resetSearch = useCallback(() => {
    setSearchText("");

    resetFilters();
    resetSelectableFilters();
    resetResults();
    resetStateData();
    setMyLocation(null);
    // we do not reset zoom as of now
    // goHome();
  }, []);

  const selectedDatasets = useMemo(() => {
    return datasetResponse?.items || [];
  }, [datasetResponse]);

  const resetSelectableFilters = () => {
    setSelectedFileState(null);
    setSelectedDatasetState(null);
    setSelectedSolutionFile(null);
    setSelectedSolutionDataset(null);
    setSelectedMediaDataType([]);
  };

  const resetFilters = () => {
    setRightSideFilter([]);
    setDatasetResponse(null);
    setSearchHelperText("");
  };

  const resetResults = () => {
    setRespData({} as FilePage);
    setPylonRespData({} as ApiHvPylonResponse);
    resetStateData();
  };

  const resetStateData = () => {
    // reset stuff initially
    setSearchHelperText("");
    setSelectedId(null);
    setHoverId(null);
  };

  const resetSearchBeforeRightSideSearch = () => {
    setSearchType(SEARCH_TYPE.DATASET);

    setSelectedSolutionFile(null);
    setSelectedSolutionDataset(null);
    setSelectedFileState(null);
    setSelectedDatasetState(null);
    setClickedDataset(null);
    setSearchText("");
    resetFilters();
    resetResults();
  };

  useEffect(() => {
    const viewer = viewerRef.current;

    if (
      tileZoomLevel < 12 ||
      !viewer ||
      currentViewPortPolygon == "" ||
      selectedPylon
    )
      return;
    if (pylonReq.current) clearTimeout(pylonReq.current);

    pylonReq.current = setTimeout(() => {
      pylonReq.current = null;
      const polygonArray = currentViewPortPolygon
        .split(",")
        .map((item) => item.trim().split(" "));
      // pylons
      gridApiCall<gridPaths["/electricity/hv-pylons"]>(
        `/electricity/hv-pylons?polygon=${encodeURI(
          JSON.stringify({
            type: "Polygon",
            coordinates: [polygonArray],
          }),
        )}`,
      ).then((resp) => {
        setInitialPylonList(resp);
      });
    }, 500);
  }, [tileZoomLevel, currentViewPortPolygon, selectedPylon]);

  useEffect(() => {
    // initial fetch
    MasterDataApi.findSolutions(0, masterRecordLimit).then((resp) => {
      setSolutions(resp.data);
    });
  }, []);

  useEffect(() => {
    const getImageData = async () => {
      const fileId = selectedId || hoverId;
      if (fileId) {
        if (!(fileId in imageDetailsCache)) {
          setSelectedImageDetails(null);
          const { data: imageData } = await FileApi.findFileById(fileId, {
            headers: {
              Accept: "application/json",
            },
          });
          // const imageData = await apiCall<paths["/files/{file-id}"]>(
          //   `/files/${fileId}`,
          // );
          imageDetailsCache[fileId] = imageData;
          setSelectedImageDetails(imageDetailsCache[fileId]);
        } else {
          setSelectedImageDetails(imageDetailsCache[fileId]);
        }
      }
    };
    getImageData();
  }, [selectedId, hoverId]);

  const pylonTypeSearch = useCallback(() => {
    if (clickedDataset) return;
    gridApiCallWithResetState<gridPaths["/electricity/hv-pylons"]>(
      `/electricity/hv-pylons?filter=equals(name,'${searchText}')&limit=${masterRecordLimit}`,
    )
      .then(async (res) => {
        if (!res?.data?.length) {
          setSearchHelperText("Nothing found? Switch to Dataset.");
          setSearchLoading(false);
          return;
        }

        setPylonRespData(res);
        setSelectedPylon(res.data[0]);
        setSearchLoading(false);
      })
      .catch((error) => {
        setSearchLoading(false);
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 404 ||
          error?.response?.status === 503
        ) {
          // on 404 error we set data to empty
          // so we can show error message about empty data
          // as api is limited to send 404 for id search
          setSearchHelperText("Network Error.");
        } else {
          setRespData(null);
        }
      });
  }, [searchText, clickedDataset]);

  const datasetTypeSearch = useCallback(() => {
    // initial get id of dataset from the name
    resetStateData();
    DatasetApi.findDatasets(
      0,
      masterRecordLimit,
      undefined,
      undefined,
      undefined,
      searchText,
    )
      .then(async ({ data }) => {
        if (!data.items) {
          throw Error("Network Error.");
        }
        if (data.items?.length > 0) {
          setDatasetResponse(data);
          zoomToEntities(ZOOM_TYPE.SINGLE_OFFSET);
        } else {
          // no dataset found
          setDatasetResponse(null);
          setSearchHelperText("No datasets found with given name.");
        }
        setSearchLoading(false);
      })
      .catch((error) => {
        setSearchLoading(false);
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 404 ||
          error?.response?.status === 503
        ) {
          // on 404 error we set data to empty
          // so we can show error message about empty data
          // as api is limited to send 404 for id search
          setSearchHelperText("Network Error.");
        } else {
          setRespData({} as FilePage);
        }
      });
  }, [searchText]);

  const hasResults = useMemo(() => {
    return (
      !!selectedPylon ||
      !!datasetResponse?.items ||
      !!respData?.items ||
      selectedDatasets.length > 0 ||
      rightSideFilter.length > 0 ||
      !!(
        isPylonSearch &&
        (selectedSolutionDataset ||
          selectedSolutionFile ||
          selectedDatasetState ||
          selectedFileState)
      )
    );
  }, [
    respData,
    datasetResponse,
    selectedPylon,
    selectedDatasets,
    rightSideFilter,
    isPylonSearch,
    selectedSolutionFile,
    selectedSolutionDataset,
    selectedFileState,
    selectedDatasetState,
  ]);

  useEffect(() => {
    if (isLoaded.current && selectedPylon) {
      datasetListSearch();
    }
  }, [selectedPylon, selectedDatasetState, selectedSolutionDataset]);

  useEffect(() => {
    if (isLoaded.current && !isPylonSearch && hasResults) {
      datasetListSearch();
    }
  }, [
    hasResults,
    isPylonSearch,
    selectedDatasetState,
    selectedSolutionDataset,
  ]);

  const datasetListSearch = useCallback(
    async (additionalFilters: Record<string, string>[] = []) => {
      setResultLoading(true);
      const filters: Record<string, string>[] = [];

      if (rightSideFilter.length > 0) {
        additionalFilters = rightSideFilter;
      }

      // for pylon search
      if (
        selectedPylon &&
        selectedPylon.attributes &&
        selectedPylon.attributes.geometry
      ) {
        const coOrd = selectedPylon.attributes.geometry.coordinates;
        if (coOrd) {
          const geom = `SRID=4326;POINT (${coOrd[0]} ${coOrd[1]})`;
          filters.push({ key: "geom", value: geom });
        }
      }

      if (additionalFilters.length > 0) {
        additionalFilters.forEach((searchParam) => {
          filters.push(searchParam);
        });
      }

      if (selectedSolutionDataset) {
        filters.push({
          key: "solution_id",
          value: selectedSolutionDataset.id ? selectedSolutionDataset.id : "",
        });
      }

      if (selectedDatasetState) {
        filters.push({
          key: "state",
          value: selectedDatasetState,
        });
      }

      const filtersObj = {
        geom: undefined,
        solution_id: undefined,
        state: undefined,
      };

      filters.forEach((item) => {
        filtersObj[item.key] = item.value;
      });

      const { data: datasetResponse } = await DatasetApi.findDatasets(
        0,
        masterRecordLimit,
        "created_at",
        filtersObj.solution_id,
        filtersObj.state,
        searchText,
        filtersObj.geom,
      );

      // no dataset for pylon
      setSearchHelperText(
        datasetResponse.items.length == 0
          ? "Nothing found? Switch to Dataset."
          : "",
      );

      // zoom to entities
      zoomToEntities(
        datasetResponse.items.length == 0 && !selectedPylon
          ? ZOOM_TYPE.NO_OFFSET
          : ZOOM_TYPE.SINGLE_OFFSET,
      );

      setDatasetResponse(datasetResponse);
      setResultLoading(false);
    },
    [
      searchText,
      selectedPylon,
      selectedDatasetState,
      selectedSolutionDataset,
      rightSideFilter,
    ],
  );

  // initial search
  const inputSearch = useCallback(async () => {
    if (searchText == "") {
      // return;
    }

    // reset all data with input search
    resetFilters();
    resetResults();

    setSearchLoading(true);

    if (isPylonSearch) {
      pylonTypeSearch();
    } else {
      datasetTypeSearch();
    }
  }, [isPylonSearch, pylonTypeSearch, datasetTypeSearch]);

  const createFileSearchCalls = useCallback(
    (additionalFilters) => {
      const searchCalls: Promise<AxiosResponse<FilePage, any>>[] = [];
      const filters: Record<string, string>[] = [];

      if (clickedDataset) {
        filters.push({
          key: "dataset_id",
          value: clickedDataset.id ? clickedDataset.id : "",
        });
      }

      if (additionalFilters.length > 0) {
        additionalFilters.forEach((searchParam) => {
          filters.push(searchParam);
        });
      }

      const filtersObj = {
        dataset_id: "",
      };

      filters.forEach((item) => {
        filtersObj[item.key] = item.value;
      });

      if (selectedMediaDataType.length > 0) {
        selectedMediaDataType.forEach((type) => {
          searchCalls.push(
            DatasetApi.findFilesByDatasetId(
              filtersObj.dataset_id,
              0,
              masterRecordLimit,
              "created_at",
              type,
            ),
          );
        });
      } else {
        searchCalls.push(
          DatasetApi.findFilesByDatasetId(filtersObj.dataset_id),
        );
      }
      return searchCalls;
    },
    [clickedDataset, selectedMediaDataType],
  );

  // filter search
  const filterResults = useCallback(
    async (additionalFilters: Record<string, string>[] = []) => {
      if (!clickedDataset) return;
      // file extra filters
      setResultLoading(true);

      resetStateData();
      const searchCalls: Promise<AxiosResponse<FilePage, any>>[] =
        createFileSearchCalls(additionalFilters);

      let combinedResponse: FilePage | undefined;
      const results = await Promise.all(searchCalls);

      results.forEach(({ data }) => {
        if (!data || !data.count || !data.items) return;
        if (!combinedResponse) {
          combinedResponse = data;
          return;
        }
        combinedResponse.count += data.count;
        combinedResponse.count += data.count;
        combinedResponse.items = combinedResponse.items.concat(data.items);
      });

      if (combinedResponse && combinedResponse?.items.length > 0) {
        setRespData(combinedResponse);
        zoomToEntities(ZOOM_TYPE.SINGLE_OFFSET);
      } else {
        setRespData(null);
      }

      setResultLoading(false);
    },
    [
      selectedSolutionFile,
      selectedMediaDataType,
      selectedFileState,
      isPylonSearch,
      clickedDataset,
    ],
  );

  useEffect(() => {
    if (hasResults) {
      filterResults();
    }
  }, [
    clickedDataset,
    selectedMediaDataType,
    selectedFileState,
    selectedSolutionFile,
    selectedSolutionDataset,
  ]);

  useEffect(() => {
    const makeRightSideSearch = async () => {
      try {
        await datasetListSearch();
        setSearchButtonLoading("");
      } catch (e) {
        console.log(e);
        setSearchButtonLoading("");
      }
    };
    if (rightSideFilter.length > 0) {
      makeRightSideSearch();
    }
  }, [rightSideFilter]);

  const selectedObject: FileDetail | undefined = useMemo(() => {
    if (selectedId) {
      if (respData?.items) {
        const result = respData.items.find((item) => item.id === selectedId);
        if (result) return result as unknown as FileDetail;
        return undefined;
      }
      return undefined;
    }
    return undefined;
  }, [selectedId, respData]);

  useEffect(() => {
    zoomToEntities(
      ZOOM_TYPE.SINGLE_OFFSET,
      // selectedObject ? ZOOM_TYPE.DOUBLE_OFFSET : ZOOM_TYPE.SINGLE_OFFSET,
    );
  }, [selectedObject]);

  useEffect(() => {
    zoomToEntities(ZOOM_TYPE.SINGLE_OFFSET);
  }, [clickedDataset]);

  const calculateTerrainHeightAtPosition = useCallback(
    async (position: Cesium.Cartesian3) => {
      const viewer = viewerRef.current;
      if (!viewer) return;
      try {
        const cartographic = Cesium.Cartographic.fromCartesian(position);
        // Load the most detailed terrain data for the given cartographic position
        const updatedPositions = await Cesium.sampleTerrainMostDetailed(
          viewer.terrainProvider,
          [cartographic],
        );
        const { height } = updatedPositions[0];
        return height;
      } catch (_e) {
        return;
      }
    },
    [],
  );

  const zoomToEntities = useCallback(
    (offsetType: ZOOM_TYPE = ZOOM_TYPE.NO_OFFSET) => {
      // in 3D no automatic zoom as it is meant for 2d only for now
      // if we use auto zoom in 3D it will reset viewport most of time
      if (is3D) return;

      const showZoomBox = false;
      const viewer = viewerRef.current;
      if (!viewer) return;
      setTimeout(() => {
        let mapEntities;
        try {
          mapEntities = viewer.entities.values.filter(
            (item) => !["selectorRect"].includes(item.id),
          );
        } catch (e) {
          console.log("zoom error", e);
          return;
        }

        const bbox = calculateBoundingBox(
          selectedObject && selectedEntity ? [selectedEntity] : mapEntities,
        );

        if (!bbox) return;
        const west = bbox?.minLng;
        const south = bbox?.minLat;
        const east = bbox?.maxLng;
        const north = bbox?.maxLat;

        const initialOffset = 0.8 * (east - west); // 0.5;;
        let offset = initialOffset;
        let exOffset = west - east;

        const width = south - north;

        if (exOffset <= 0.0001) {
          // for single entities
          exOffset = -0.0012;
          offset = 0.001;
        }

        if (offset <= 0.0001) {
          // avoid error
          offset = 0.01;
          exOffset = -0.1;
        }

        let leftOffset = 0;
        switch (offsetType) {
          case ZOOM_TYPE.SINGLE_OFFSET:
            leftOffset = exOffset * 1.1; // 1.5
            break;
          case ZOOM_TYPE.DOUBLE_OFFSET:
            leftOffset = exOffset * 2.5;
            break;
          default:
            break;
        }

        // long distance scenario
        if (width <= -0.01) {
          // avoid irregular zoom
          offset = initialOffset;
          leftOffset = leftOffset * 100;
        }

        const viewOffset = Cesium.Rectangle.fromDegrees(
          west - offset + leftOffset,
          south - offset,
          east + offset,
          north + offset,
        );

        viewer.entities.removeById("raw");
        showZoomBox &&
          viewer.entities.add({
            id: "raw",
            show: true,
            rectangle: {
              coordinates: Cesium.Rectangle.fromDegrees(
                west - offset,
                south - offset,
                east + offset,
                north + offset,
              ),
              fill: false,
              outline: true, // height must be set for outline to display
              outlineColor: Cesium.Color.BLACK.withAlpha(1),
            },
          });

        // avoid excessive zoom
        if (viewOffset.east - viewOffset.west < 0.000025) {
          const num = 0.00001;
          viewOffset.east = viewOffset.east + num;
          viewOffset.west = viewOffset.west - num;
        }

        viewer.entities.removeById("modified");
        showZoomBox &&
          viewer.entities.add({
            id: "modified",
            show: true,
            rectangle: {
              coordinates: viewOffset,
              fill: false,
              outline: true, // height must be set for outline to display
              outlineColor: Cesium.Color.RED.withAlpha(1),
            },
          });

        const options = {
          destination: viewOffset,
          orientation: {
            heading: 0.0, // east, default value is 0.0 (north)
            pitch: Cesium.Math.toRadians(-90), // default value (looking down)
            roll: 0.0, // default value
          },
          duration: 1,
        };

        viewer.camera.flyTo(options);
      }, 1);
    },
    [is3D],
  );

  const toggleSearchType = (argType) => {
    if (searchType === argType) return;
    setSearchType(argType);
    resetSearch();
  };

  const toggleSelectedMediaDataType = (argType: MediaType) => {
    setSelectedMediaDataType((types) =>
      types.includes(argType)
        ? types.filter((t) => t !== argType)
        : types.concat([argType]),
    );
  };

  const goHome = (animation: boolean = true) => {
    if (!viewerRef.current) {
      return;
    }

    const zoomOption = {
      // destination: rectangle,
      destination: Cesium.Cartesian3.fromDegrees(
        10.322283,
        51.265259,
        1500000.0,
      ),
      duration: 1,
    };

    if (animation) {
      viewerRef.current.camera.flyTo(zoomOption);
    } else {
      viewerRef.current.camera.setView(zoomOption);
    }
  };

  // on hover
  useEffect(() => {
    const viewer = viewerRef.current;
    if (rollbackStyleLastHoverEntityFn) {
      try {
        rollbackStyleLastHoverEntityFn();
      } catch (e) {
        // entity can't be removed from the map
      }
    }
    const allHoverId = hoverId || hoverDatasetId;

    if (allHoverId && viewer) {
      const entity = viewer.entities.getById(allHoverId);
      if (entity?.point) {
        entity.point.color = new Cesium.ConstantProperty(
          EntityGraphicsState.HOVER_file_Point_Graphics.color,
        );
        entity.point.pixelSize = new Cesium.ConstantProperty(
          EntityGraphicsState.HOVER_file_Point_Graphics.pixelSize,
        );
        rollbackStyleLastHoverEntityFn = () => {
          if (entity?.point) {
            entity.point.color = new Cesium.ConstantProperty(
              EntityGraphicsState.NORMAL_file_Point_Graphics.color,
            );
            entity.point.pixelSize = new Cesium.ConstantProperty(
              EntityGraphicsState.NORMAL_file_Point_Graphics.pixelSize,
            );
          }
        };
      } else if (entity?.ellipsoid) {
        entity.ellipsoid.material = new Cesium.ColorMaterialProperty(
          EntityGraphicsState.HOVER_file_Ellipsoid_Graphics.material,
        );
        entity.ellipsoid.radii = new Cesium.ConstantProperty(
          EntityGraphicsState.HOVER_file_Ellipsoid_Graphics.radii,
        );
        rollbackStyleLastHoverEntityFn = () => {
          if (entity?.ellipsoid) {
            entity.ellipsoid.material = new Cesium.ColorMaterialProperty(
              EntityGraphicsState.NORMAL_file_Ellipsoid_Graphics.material,
            );
            entity.ellipsoid.radii = new Cesium.ConstantProperty(
              EntityGraphicsState.NORMAL_file_Ellipsoid_Graphics.radii,
            );
          }
        };
      } else if (entity?.polygon) {
        const type = Cesium.defined(entity?.properties?.type)
          ? entity?.properties?.type.getValue()
          : "";
        const isFile =
          type === "file_point_cloud_3d" || type === "file_point_cloud_2d";
        const HoverState = isFile
          ? EntityGraphicsState.HOVER_file_Polygon_graphics
          : EntityGraphicsState.HOVER_dataset_Polygon_graphics;
        const NormalState = isFile
          ? EntityGraphicsState.NORMAL_file_Polygon_graphics
          : EntityGraphicsState.NORMAL_dataset_Polygon_graphics;
        entity.polygon.material = new Cesium.ColorMaterialProperty(
          HoverState.material,
        );
        entity.polygon.outlineColor = new Cesium.ColorMaterialProperty(
          HoverState.outlineColor,
        );
        rollbackStyleLastHoverEntityFn = () => {
          if (entity?.polygon) {
            entity.polygon.material = new Cesium.ColorMaterialProperty(
              NormalState.material,
            );
            entity.polygon.outlineColor = new Cesium.ColorMaterialProperty(
              NormalState.outlineColor,
            );
          }
        };
      }
    }
  }, [hoverId, hoverDatasetId]);

  const onload = (viewer: Cesium.Viewer, hasRef = false) => {
    // set ref
    viewerRef.current = viewer;
    isLoaded.current = true;

    if (hasRef) return;
    setIsMapLoaded(true);
    goHome(false);

    viewer.scene.imageryLayers.removeAll();
    viewer.scene.globe.baseColor = Cesium.Color.BLACK;
    // for terrain entity stick to ground
    viewer.scene.globe.depthTestAgainstTerrain = true;
    // By default, the `camera.changed` event will trigger when the camera has changed by 50%
    // To make it more sensitive, we can bring down this sensitivity
    viewer.camera.percentageChanged = 0.01;

    // map events
    viewer.scene.globe.tileLoadProgressEvent.addEventListener(() => {
      const glob = viewer.scene.globe as GlobExtended;
      const tilesToRender = glob._surface._tilesToRender;
      const length = tilesToRender.length;
      if (length > 0) {
        setTileZoomLevel(tilesToRender[0]?._level);
      }
    });

    // double click issue fixed
    viewer.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK,
    );

    // camera move end event
    viewer.camera.changed.addEventListener(() => {
      setCurrentViewPortPolygon(getViewerViewArea(viewer));
    });

    // hover cursor pointer
    viewer.screenSpaceEventHandler.setInputAction((movement) => {
      try {
        const pickedFeature = viewer.scene.drillPick(movement.endPosition);
        viewer.canvas.style.cursor =
          pickedFeature.length > 0 ? "pointer" : "initial";
      } catch (e) {
        // nothing for now
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    // in 3d space select entity through box
    viewer.screenSpaceEventHandler.setInputAction((movement) => {
      try {
        const pickedFeature = viewer.scene.drillPick(movement.position);
        pickedFeature.forEach((item) => {
          if (Cesium.defined(item.id) && item?.id?.properties?.type) {
            if (["file_3d"].includes(item?.id?.properties?.type.getValue())) {
              setSelectedId(item?.id?.id);
            }
          }
        });
      } catch (e) {
        console.log(e);
        // nothing for now
      }
    }, Cesium.ScreenSpaceEventType.LEFT_UP);

    // event handler as resium component has issue onSelectedEntityChange
    viewer.selectedEntityChanged.addEventListener((selectedEntity) => {
      if (userSelectingRef.current) return;
      if (Cesium.defined(selectedEntity) && selectedEntity?.properties?.type) {
        if (
          [
            "file_3d",
            "file_2d",
            "file_point_cloud_3d",
            "file_point_cloud_2d",
          ].includes(selectedEntity.properties.type.getValue())
        ) {
          if (Cesium.defined(selectedEntity.id)) {
            setSelectedId(selectedEntity.id);
          } else {
            // Unknown entity selected.
          }
        } else {
          setSelectedId(null);
        }
      } else {
        // we cant remove pylon as it will remove whole hierarchy
        // pylon -> dataset -> file
        // setSelectedPylon(null);
      }
    });

    // viewer.scene.morphTo2D(0);
    viewer.scene.screenSpaceCameraController.minimumZoomDistance = 192;
    viewer.scene.screenSpaceCameraController.maximumZoomDistance = 6378137 * 2;

    // THIS
    viewer.scene.screenSpaceCameraController.enableTilt = false;

    viewer.scene.screenSpaceCameraController.enableRotate = false;
    viewer.scene.screenSpaceCameraController.enableLook = false;

    viewer.scene.screenSpaceCameraController.inertiaZoom = 0;
  };

  useEffect(() => {
    if (viewerRef.current) {
      const viewer = viewerRef.current;
      if (is3D) {
        viewer.scene.screenSpaceCameraController.enableTilt = true;
      } else {
        viewer.scene.screenSpaceCameraController.enableTilt = false;
        const cartesian = Cesium.Cartographic.toCartesian(
          viewer.camera.positionCartographic,
        );
        viewer.camera.flyTo({
          destination: cartesian,
          orientation: {
            heading: 0.0,
            pitch: Cesium.Math.toRadians(-90),
            roll: 0.0,
          },
          duration: 1,
        });
      }
    }
  }, [is3D]);

  const selectedEntity = useMemo(() => {
    if (viewerRef.current && selectedId) {
      const viewer = viewerRef.current;
      const entity = viewer.entities.getById(selectedId);
      if (entity) {
        return entity;
      }
    }
    return undefined;
  }, [selectedId]);

  const zoomToLocation = () => {
    const viewer = viewerRef.current;
    if (!viewer) return;
    setTimeout(() => {
      const locationEntity = viewer.entities.getById("my_location");

      if (!locationEntity) return;
      const zoomOffsetResult = hasResults ? 0 : -1;
      const zoomOffsetType = selectedObject ? 1 : zoomOffsetResult;
      // const zoomOffsetType = 1
      const bbox = calculateBoundingBox([locationEntity]);
      if (!bbox) return;

      const west = bbox?.minLng;
      const south = bbox?.minLat;
      const east = bbox?.maxLng;
      const north = bbox?.maxLat;

      const offset = 0.0002;
      const exOffset = -0.0005;

      const otherOffset = zoomOffsetType == 0 ? exOffset : exOffset * 1.7;
      const leftOffset = zoomOffsetType == -1 ? 0 : otherOffset;
      const viewOffset = Cesium.Rectangle.fromDegrees(
        west - offset + leftOffset,
        south - offset,
        east + offset,
        north + offset,
      );

      // avoid excessive zoom
      if (viewOffset.east - viewOffset.west < 0.000025) {
        const num = 0.00001;
        viewOffset.east = viewOffset.east + num;
        viewOffset.west = viewOffset.west - num;
      }

      const options = {
        destination: viewOffset,
        orientation: {
          heading: 0.0, // east, default value is 0.0 (north)
          pitch: Cesium.Math.toRadians(-90), // default value (looking down)
          roll: 0.0, // default value
        },
        duration: 1,
      };

      viewer.camera.flyTo(options);
    }, 100);
  };

  const setMyLocationWithOption = useCallback(
    async ({
      location,
      withSearch = false,
    }: {
      location: GeoJSON.Point | null;
      withSearch?: boolean;
    }) => {
      const viewer = viewerRef.current;
      if (location == null) {
        setMyLocation(location);
        return;
      }
      if (viewer && location) {
        setMyLocation(location);
        if (withSearch) {
          setSearchButtonLoading(
            RIGHT_SIDE_SEARCH_BUTTON_ID.userLocationBasedSearch,
          );
          const coOrd = location.coordinates;
          const geom = `SRID=4326;POINT (${coOrd[0]} ${coOrd[1]})`;

          // reset all data with input search
          resetSearchBeforeRightSideSearch();

          const { data: datasetResponse } = await DatasetApi.findDatasets(
            0,
            masterRecordLimit,
            "created_at",
            undefined,
            undefined,
            searchText,
            geom,
          );

          if (
            datasetResponse &&
            datasetResponse?.items &&
            datasetResponse.items.length > 0
          ) {
            setDatasetResponse(datasetResponse);
          }
          setSearchButtonLoading("");
        }
        zoomToLocation();
      }
    },
    [hasResults, selectedEntity],
  );

  useEffect(() => {
    const viewer = viewerRef.current;
    if (!viewer) return;

    const screenSpaceEventHandler = new Cesium.ScreenSpaceEventHandler(
      viewer.scene.canvas,
    );
    // add selector
    let selectorRect: Cesium.Entity | null = viewer.entities.add({
      id: "selectorRect",
      show: false,
      isShowing: false,
      rectangle: {
        coordinates: new Cesium.Rectangle(),
        material: Cesium.Color.RED.withAlpha(0.3),
      },
    });

    const executeSwitch = async () => {
      switch (searchButtonId) {
        /*case RIGHT_SIDE_SEARCH_BUTTON_ID.processAtPointSearch: {
            screenSpaceEventHandler.setInputAction(
              (clickEvent: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
                const cartesian = viewer.camera.pickEllipsoid(
                  clickEvent.position,
                  viewer.scene.globe.ellipsoid,
                ) as Cesium.Cartesian3;

                // mouse cartographic
                const cartographic = Cesium.Cartographic.fromCartesian(
                  cartesian,
                  Cesium.Ellipsoid.WGS84,
                );

                const longitudeString = Cesium.Math.toDegrees(
                  cartographic.longitude,
                );
                const latitudeString = Cesium.Math.toDegrees(
                  cartographic.latitude,
                );
                const longLat = `${longitudeString} ${latitudeString}`;

                const geomPoint = `SRID=4326;POINT (${longLat})`;
                const endpoint = `/processes?geom=${geomPoint}&limit=${masterRecordLimit}`;
                const title = `Your process result for search geom '${longLat}'`;
                loadData({
                  endpoint,
                  _resource: "processes",
                  searchTitleText: title,
                });
                setSearchButtonId("");
              },
              Cesium.ScreenSpaceEventType.LEFT_DOWN,
            );
            break;
          }*/
        case RIGHT_SIDE_SEARCH_BUTTON_ID.datasetInView:
        case RIGHT_SIDE_SEARCH_BUTTON_ID.fileInView: {
          setSearchButtonId("");
          setSearchButtonLoading(searchButtonId);

          const geomPolygon = `SRID=4326;POLYGON ((${getViewerViewArea(viewer)}))`;
          const additionalFilters = [{ key: "geom", value: geomPolygon }];
          // reset all data with input search
          resetSearchBeforeRightSideSearch();
          setRightSideFilter(additionalFilters);
          break;
        }
        case RIGHT_SIDE_SEARCH_BUTTON_ID.datasetViaAreaOfInterest:
        case RIGHT_SIDE_SEARCH_BUTTON_ID.fileViaAreaOfInterest: {
          const onSelectionComplete = async (polygonString: string) => {
            const geomPolygon = `SRID=4326;POLYGON ((${polygonString}))`;
            const additionalFilters = [{ key: "geom", value: geomPolygon }];

            setSearchButtonId("");
            setSearchButtonLoading(searchButtonId);

            // reset all data with input search
            resetSearchBeforeRightSideSearch();
            setRightSideFilter(additionalFilters);
          };
          await areaOfInterestLogic(
            viewer,
            screenSpaceEventHandler,
            selectorRect,
            userSelectingRef,
            onSelectionComplete,
          );
          break;
        }
        default: {
          break;
        }
      }
    };

    executeSwitch();

    return () => {
      screenSpaceEventHandler.destroy();
      if (selectorRect) {
        viewer.entities.remove(selectorRect);
        selectorRect = null;
      }
    };
  }, [searchButtonId]);

  const resetDateRangeDataset = () => {
    if (selectedDatasets.length === 0) {
      setDateRangeDataset([null, null]);
    } else {
      let earliestDate = dayjs(dayjs(selectedDatasets[0].created_at));
      selectedDatasets.forEach((dataset) => {
        if (dayjs(earliestDate).isAfter(dayjs(dataset.created_at))) {
          earliestDate = dayjs(dataset.created_at);
        }
      });
      setDateRangeDataset([
        earliestDate.isValid() ? earliestDate : dayjs(),
        dayjs(),
      ]);
    }
  };

  const resetDateRangeFile = () => {
    if (!respData || !respData.items) {
      setDateRangeFile([null, null]);
      return;
    }

    if (respData.items.length === 0) {
      setDateRangeFile([null, null]);
    } else {
      let earliestDate = dayjs(dayjs(respData.items[0].created_at));
      respData.items.forEach((file) => {
        if (dayjs(earliestDate).isAfter(dayjs(file.created_at))) {
          earliestDate = dayjs(file.created_at);
        }
      });
      setDateRangeFile([
        earliestDate.isValid() ? earliestDate : dayjs(),
        dayjs(),
      ]);
    }
  };

  useEffect(() => {
    resetDateRangeDataset();
  }, [selectedDatasets]);

  useEffect(() => {
    resetDateRangeFile();
  }, [respData]);

  const filteredFile: FilePage = useMemo(() => {
    const earliestDate =
      dateRangeFile[0] !== null ? dateRangeFile[0] : dayjs("1970-00-00");
    const latestDate = dateRangeFile[1] !== null ? dateRangeFile[1] : dayjs();
    const items = respData?.items
      ? respData.items.filter((file: FileDetail) =>
          dayjs(file.created_at).isBetween(
            earliestDate,
            latestDate,
            "day",
            "[]",
          ),
        )
      : [];
    return {
      ...respData,
      count: items.length,
      items,
    } as FilePage;
  }, [respData, dateRangeFile]);

  const filteredDatasets = useMemo(() => {
    const earliestDate =
      dateRangeDataset[0] !== null ? dateRangeDataset[0] : dayjs("1970-00-00");
    const latestDate =
      dateRangeDataset[1] !== null ? dateRangeDataset[1] : dayjs();
    return selectedDatasets.filter((dataset) =>
      dayjs(dataset.created_at).isBetween(
        earliestDate,
        latestDate,
        "day",
        "[]",
      ),
    ) as unknown as Dataset[];
  }, [selectedDatasets, dateRangeDataset]);

  return (
    <Box className={classes.container}>
      <Box
        className={clsx(classes.sidebar, {
          [classes.sidebarActive]: hasResults,
        })}
        marginBottom={2}
      >
        <InputSearch
          setSearchText={setSearchText}
          searchText={searchText}
          inputSearch={inputSearch}
          searchLoading={searchLoading}
          isPylonSearch={isPylonSearch}
          toggleSearchType={toggleSearchType}
          resetSearch={resetSearch}
          searchHelperText={searchHelperText}
        />
        {hasResults && (
          <Results
            respData={filteredFile}
            selectedDatasets={filteredDatasets}
            isPylonSearch={isPylonSearch}
            selectedPylon={selectedPylon}
            clickedDataset={clickedDataset}
            setHoverDatasetId={setHoverDatasetId}
            setClickedDataset={setClickedDataset}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            setHoverId={setHoverId}
            resultLoading={resultLoading}
            selectedFileState={selectedFileState}
            selectedDatasetState={selectedDatasetState}
            setSelectedDatasetState={setSelectedDatasetState}
            setSelectedFileState={setSelectedFileState}
            solutions={solutions}
            selectedSolution={
              !clickedDataset ? selectedSolutionDataset : selectedSolutionFile
            }
            setSelectedSolution={
              !clickedDataset
                ? setSelectedSolutionDataset
                : setSelectedSolutionFile
            }
            dateRange={!clickedDataset ? dateRangeDataset : dateRangeFile}
            setDateRange={
              !clickedDataset ? setDateRangeDataset : setDateRangeFile
            }
            resetDateRange={
              !clickedDataset ? resetDateRangeDataset : resetDateRangeFile
            }
          />
        )}
      </Box>
      <TopFilters
        selectedMediaDataType={selectedMediaDataType}
        toggleSelectedMediaDataType={toggleSelectedMediaDataType}
      />
      {selectedObject && (
        <ResultsWindow
          selectedObject={selectedObject}
          selectedImageDetails={selectedImageDetails}
          setSelectedId={setSelectedId}
        />
      )}
      <MapSwitcher
        is3D={is3D}
        hasResults={hasResults}
        setIsBaseLayer={setIsBaseLayer}
        isBaseLayer={isBaseLayer}
        setIs3D={setIs3D}
      />
      <Box className={clsx({ [classes.selectPoint]: searchButtonId })}>
        <Viewer
          full
          ref={(r) => {
            if (r?.cesiumElement) {
              onload(r?.cesiumElement, !!viewerRef?.current);
            }
          }}
          contextOptions={contextOptions}
          sceneMode={Cesium.SceneMode.COLUMBUS_VIEW}
          animation={false}
          timeline={false}
          sceneModePicker={false}
          fullscreenButton={false}
          baseLayerPicker={false}
          maximumRenderTimeChange={Cesium.SceneMode.SCENE2D}
          infoBox={false}
          homeButton={false}
          geocoder={false}
          navigationHelpButton={false}
          mapProjection={mapProjection}
          selectedEntity={selectedEntity}
          terrain={terrain}
          // selectionIndicator={isPylonSearch ? false : true}
        >
          {isMapLoaded && (
            <>
              <ImageryLayer
                show={isBaseLayer}
                imageryProvider={topoTiles.imageryProvider}
              />
              <ImageryLayer
                show={!isBaseLayer}
                imageryProvider={satelliteTiles.imageryProvider}
              />
              <LocationGeoJsonComponent locationData={myLocation} />
              {isPylonSearch && (
                <PylonGeoJsonComponent
                  pylonList={tileZoomLevel >= 12 ? initialPylonList : {}}
                  selectedPylon={selectedPylon}
                  setSelectedPylon={setSelectedPylon}
                  selectedDatasets={filteredDatasets}
                  heightFunction={calculateTerrainHeightAtPosition}
                  is3D={is3D}
                />
              )}
              {!clickedDataset && (
                <DatasetGeoJsonComponent
                  selectedDatasets={selectedDatasets}
                  setClickedDataset={setClickedDataset}
                  hoverDatasetId={hoverDatasetId}
                  isPylonSearch={isPylonSearch}
                  is3D={is3D}
                />
              )}
              {clickedDataset && (
                <FileGeoJsonComponent
                  clickedDataset={clickedDataset}
                  isPylonSearch={isPylonSearch}
                  mapData={filteredFile}
                  is3D={is3D}
                  hoverId={hoverId}
                  selectedImageDetails={selectedImageDetails}
                  heightFunction={calculateTerrainHeightAtPosition}
                />
              )}
              <RightSideControls
                myLocation={myLocation}
                searchButtonLoading={searchButtonLoading}
                setSearchButtonLoading={setSearchButtonLoading}
                setSearchButtonId={setSearchButtonId}
                searchButtonId={searchButtonId}
                setMyLocationWithOption={setMyLocationWithOption}
              />
            </>
          )}
        </Viewer>
      </Box>
    </Box>
  );
};
