import React, { useMemo } from "react";
import { Entity, PolygonGraphics } from "resium";
import * as Cesium from "cesium";
import { EntityGraphicsState } from "../MDPViewer";
import { parse2DPosFromPolygonRaw } from "../../../util/coordinates";
import { Dataset } from "../../../openapi/api";

interface DatasetGeoJsonComponent {
  isPylonSearch: boolean;
  selectedDatasets: Dataset[];
  hoverDatasetId: string | null;
  setClickedDataset: (dataset: Dataset | null) => void;
  is3D: boolean;
}

export const DatasetGeoJsonComponent = ({
  isPylonSearch,
  selectedDatasets,
  hoverDatasetId,
  setClickedDataset,
  is3D,
}: DatasetGeoJsonComponent) => {
  const hoveredDataset = useMemo(() => {
    if (isPylonSearch && !hoverDatasetId) return null;
    const dataset = selectedDatasets.find((item) => item.id === hoverDatasetId);
    if (!dataset || !dataset?.bounding_box_geometry) return null;
    return <DatasetGraphics key={dataset.id} dataset={dataset} is3D={is3D} />;
  }, [hoverDatasetId, selectedDatasets, is3D]);

  const datasets = useMemo(() => {
    return selectedDatasets.map((dataset) => {
      return (
        <DatasetGraphics
          dataset={dataset}
          key={dataset.id}
          is3D={is3D}
          onClick={() => {
            setClickedDataset(dataset);
          }}
        />
      );
    });
  }, [selectedDatasets, is3D]);
  return isPylonSearch ? hoveredDataset : datasets;
};

export const DatasetGraphics: React.FunctionComponent<
  {
    dataset: Dataset;
    onClick?: () => void;
  } & Pick<DatasetGeoJsonComponent, "is3D">
> = ({ dataset, is3D, onClick = () => {} }) => {
  const height = useMemo(() => {
    if (!is3D) return 0;
    // const pointCloudDetails = getPointCloudData(dataset.id, "dataset");
    // if (pointCloudDetails.length > 0) {
    //   let maxHeight = 0;
    //   pointCloudDetails.forEach((item) => {
    //     maxHeight = Math.max(pointCloudDetails[0].pcMaxHeight, maxHeight);
    //   });
    //   return maxHeight;
    // }
    return 1;
  }, [dataset, is3D]);

  if (!dataset?.bounding_box_geometry) return null;
  const datasetCoords = Cesium.Cartesian3.fromDegreesArray(
    parse2DPosFromPolygonRaw(dataset?.bounding_box_geometry).flat(),
  );
  return (
    <Entity
      id={dataset.id}
      name={dataset.name}
      onClick={onClick}
      properties={{ type: is3D ? "dataset_3d" : "dataset_2d" }}
    >
      <PolygonGraphics
        hierarchy={datasetCoords}
        {...EntityGraphicsState.NORMAL_dataset_Polygon_graphics}
        heightReference={Cesium.HeightReference.RELATIVE_TO_TERRAIN}
        height={is3D ? 0 : 2}
        extrudedHeight={height}
      />
    </Entity>
  );
};
