import { AxiosRequestConfig } from "axios";
import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DatasetApi, FileApi, FileDetail, MasterdataApi } from "../openapi/api";
import { Configuration } from "../openapi/configuration";

export const getBaseUrl = () => {
  return `${window.REACT_APP_MEDIA_DATA_URL}`;
};

export const getApiUrl = () => {
  return `${getBaseUrl()}`;
};

export const useApi = () => {
  const { instance } = useMsal();
  const [authToken, setAuthToken] = useState<string>("");

  const getToken = useCallback(async () => {
    let token = "";
    const tenantAppId = `${window.REACT_APP_TENANT_APP_ID}`;
    try {
      const response = await instance.acquireTokenSilent({
        scopes: [`api://${tenantAppId}/.default`],
      });

      token = response.accessToken;
    } catch (error) {
      // token error
    }

    return token;
  }, []);

  useEffect(() => {
    const updateToken = async () => {
      setAuthToken(await getToken());
    };
    updateToken();
  }, [getToken]);

  // NEW
  const getBaseOptions: () => AxiosRequestConfig = useCallback(() => {
    return {};
  }, []);

  const apiConfig = useMemo(() => {
    return new Configuration({
      basePath: getBaseUrl(),
      accessToken: getToken,
      baseOptions: getBaseOptions(),
    });
  }, [getToken]);

  const DatasetApiClass = useMemo(() => new DatasetApi(apiConfig), [apiConfig]);

  const MasterDataApiClass = useMemo(
    () => new MasterdataApi(apiConfig),
    [apiConfig],
  );

  const FileApiClass = useMemo(() => new FileApi(apiConfig), [apiConfig]);

  const getImage = async (file: FileDetail) => {
    return await FileApiClass.findFileById(file.id, {
      headers: {
        Accept: file.mime,
      },
      responseType: "blob",
    });
  };

  return {
    DatasetApi: DatasetApiClass,
    MasterDataApi: MasterDataApiClass,
    FileApi: FileApiClass,
    getApiUrl,
    getImage,
  };
};
