import { makeStyles } from "tss-react/mui";

export const SidebarWidth = "400px";

export const useStyles = makeStyles()((theme) => ({
  container: {
    position: "relative",
    height: "100%",
    "& .cesium-viewer-bottom": {
      display: "flex",
      justifyContent: "flex-end",
      bottom: "0 !important",
      paddingRight: 0,
    },
    "& .cesium-widget-credits": {
      display: "flex",
      alignItems: "center",
      background: "rgba(255,255,255,0.7)",
      padding: theme.spacing(1),
      borderTopLeftRadius: theme.spacing(1),
      gap: theme.spacing(0.5),
    },
    "& .cesium-credit-wrapper": {
      display: "flex !important",
      gap: theme.spacing(1),
    },
    "& .cesium-credit-logoContainer": {
      display: "none !important",
    },
  },
  searchInput: {
    background: theme.palette.background.paper,
    borderRadius: "5px",
  },
  searchItem: {
    cursor: "pointer",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    "&:hover": {
      background: "#dddddd",
    },
  },
  sidebar: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    width: SidebarWidth,
    top: 0,
    left: 0,
    zIndex: 1,
    transition: "all 0.25s ease",
  },
  sidebarActive: {
    background: theme.palette.background.paper,
    boxShadow: "1px 0 9px 1px #c4c4c4",
    borderRight: "1px solid #dddddd",
    height: "100%",
  },
  iconChip: {
    "& .MuiChip-label": {
      paddingLeft: 0,
    },
  },
  baseMapSwitcher: {
    position: "absolute",
    left: 0,
    zIndex: 1,
    overflow: "hidden",
    display: "flex",
    gridGap: theme.spacing(1),
    transition: "left 0.25s",
    padding: theme.spacing(1),
    bottom: 0,
  },
  topControl: {
    position: "absolute",
    flexWrap: "wrap",
    zIndex: 1,
    display: "flex",
    gap: theme.spacing(1),
    left: "400px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  topToggle: {
    color: "black",
    background: theme.palette.background.paper,
  },
  topToggleActive: {
    background: theme.palette.secondary.main,
  },
  mapControlToggle: {
    padding: 0,
    border: "2px solid white",
    cursor: "pointer",
    width: "64px",
    height: "64px",
    borderRadius: theme.spacing(1),
    overflow: "hidden",
  },
  baseMapSwitcherSide: {
    left: "400px",
  },
  responsiveImage: {
    height: "auto",
    width: "100%",
  },
  selectPoint: {
    "& .cesium-viewer": {
      cursor: "crosshair",
    },
  },
  detailView: {
    position: "absolute",
    zIndex: 99,
    display: "flex",
    left: "400px",
    background: theme.palette.background.paper,
    width: `calc(100% - ${SidebarWidth})`,
    overflow: "hidden",
    height: "100%",
  },
  detailsInfo: {
    display: "flex",
    flexDirection: "column",
    "& td, & div > p, & svg": {
      color: "#8c8c8c !important",
    },
    "& .MuiTableContainer-root": {
      overflow: "initial",
    },
  },
  detailsInfoExtra: {
    "& td, & div > p, & svg": {
      fontSize: "13px",
    },
    "& tr > td:nth-child(1)": {
      whiteSpace: "nowrap",
    },
  },
  detailsTable: {
    overflow: "hidden",
  },
  detailsCell: {
    wordBreak: "break-all",
  },
  fileImagePreview: {
    position: "absolute",
    bottom: "2.5rem",
    right: "3.5rem",
    height: "300px",
    width: "auto",
    borderRadius: "10px",
  },
}));
