import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  EllipsoidGraphics,
  Entity,
  PointGraphics,
  PolygonGraphics,
  useCesium,
} from "resium";
import * as Cesium from "cesium";
import { EntityGraphicsState } from "../MDPViewer";
import { useApi } from "../../../hooks/useApi";
import { ImageCache } from "../Components/ResultsWindow";
import {
  createFileFrustum,
  parse3DPos,
  parse3DPosFromPolygonRaw,
} from "../../../util/coordinates";
import { useStyles } from "../styles";
import { Dataset, FileDetail, FilePage, MediaType } from "../../../openapi/api";

interface CesiumGeoJsonCompProp {
  mapData: FilePage;
  is3D?: boolean;
  isPylonSearch?: boolean;
  clickedDataset?: Dataset | null;
  hoverId: string | null;
  selectedImageDetails: FileDetail | null;
  heightFunction: (position: Cesium.Cartesian3) => Promise<number | undefined>;
}

const loadingStateImage: string[] = [];

export const FileGeoJsonComponent = ({
  mapData,
  isPylonSearch = false,
  is3D = false,
  clickedDataset = null,
  selectedImageDetails,
  hoverId,
  heightFunction,
}: CesiumGeoJsonCompProp) => {
  const { classes } = useStyles();
  const [, setRefresh] = useState<number>(0);
  const { getImage } = useApi();
  const [data, setData] = useState<FileDetail[]>([]);
  const { viewer } = useCesium();
  const frustumPrimitive = useRef<Cesium.Primitive | undefined>(undefined);

  useEffect(() => {
    if (mapData.items) {
      setData(mapData.items);
    } else {
      setData([]);
    }
  }, [mapData]);

  useEffect(() => {
    if (!viewer) return;
    if (!hoverId && frustumPrimitive.current) {
      viewer.scene.primitives.remove(frustumPrimitive.current);
      viewer.entities.removeById("frustum_image");
      frustumPrimitive.current = undefined;
    }
    if (!is3D || !selectedImageDetails) return;
    const hoverFile = data.find((item: FileDetail) => item.id === hoverId);
    if (hoverFile) {
      (async () => {
        const fileId = `${hoverFile.id}`;
        const position3D = parse3DPos(hoverFile.geometry);
        const terrainHeight = (await heightFunction(position3D)) || 0;
        const frustumEntity = createFileFrustum(
          viewer,
          hoverFile,
          selectedImageDetails,
          terrainHeight,
          frustumPrimitive,
        );

        // image for the frustum
        const image = new Image();
        // cache
        if (fileId in ImageCache && frustumEntity.box) {
          image.src = URL.createObjectURL(ImageCache[fileId]);
          frustumEntity.box.material = new Cesium.ImageMaterialProperty({
            image: image, // Replace with the actual URL of your image
            transparent: false, // Optional: Make the material transparent if the image has transparency
          });
        } else {
          if (!loadingStateImage.includes(fileId)) {
            loadingStateImage.push(fileId);
            getImage(hoverFile).then((resp) => {
              ImageCache[fileId] = resp.data as unknown as Blob;
              image.src = URL.createObjectURL(ImageCache[fileId]);
              image.onload = () => {
                // for image refresh
                setRefresh(new Date().getTime());
              };
              const entity = viewer.entities.getById("frustum_image");
              if (
                entity &&
                entity.box &&
                entity.properties?.fileId.getValue() === fileId
              ) {
                entity.box.material = new Cesium.ImageMaterialProperty({
                  image: image, // Replace with the actual URL of your image
                  transparent: false, // Optional: Make the material transparent if the image has transparency
                });
              }
            });
          }
        }
      })();
    }

    return () => {
      if (!viewer) return;
      if (frustumPrimitive.current) {
        viewer.scene.primitives.remove(frustumPrimitive.current);
        frustumPrimitive.current = undefined;
      }
      viewer.entities.removeById("frustum_image");
    };
  }, [data, viewer, hoverId, is3D, selectedImageDetails]);

  const files = useMemo(() => {
    const filterFn = clickedDataset
      ? (item: FileDetail) => item.dataset_id === clickedDataset.id
      : () => true;
    return data.filter(filterFn).map((marker) => {
      if (!marker.geometry) return null;
      const file = marker as FileDetail;
      if (file.type === MediaType.DroneImage) {
        const position = parse3DPos(marker.geometry);
        return (
          <Entity
            key={file.id}
            id={file.id}
            position={position}
            name={file.name}
            properties={{ type: is3D ? "file_3d" : "file_2d" }}
          >
            {is3D ? (
              <EllipsoidGraphics
                {...EntityGraphicsState.NORMAL_file_Ellipsoid_Graphics}
                heightReference={Cesium.HeightReference.RELATIVE_TO_TERRAIN}
              />
            ) : (
              <PointGraphics
                {...EntityGraphicsState.NORMAL_file_Point_Graphics}
                heightReference={Cesium.HeightReference.NONE}
                disableDepthTestDistance={Number.POSITIVE_INFINITY}
              />
            )}
          </Entity>
        );
      } else if (file.type === MediaType.PointCloud) {
        const fileRectCoord = Cesium.Cartesian3.fromDegreesArray(
          parse3DPosFromPolygonRaw(file.geometry).flat(),
        );
        const maxHeight = 1;
        // const pointCloudDetails = getPointCloudData(file.id);
        // if (pointCloudDetails.length > 0) {
        //   maxHeight = pointCloudDetails[0].pcMaxHeight;
        // }
        const graphics = is3D
          ? EntityGraphicsState.NORMAL_file_Polygon_graphics
          : EntityGraphicsState.NORMAL_file_Polygon_graphics;

        return (
          <Entity
            key={file.id}
            id={file?.id}
            selected={false}
            properties={{
              type: is3D ? "file_point_cloud_3d" : "file_point_cloud_2d",
            }}
          >
            <PolygonGraphics
              hierarchy={fileRectCoord}
              {...graphics}
              heightReference={Cesium.HeightReference.CLAMP_TO_TERRAIN}
              extrudedHeight={is3D ? maxHeight : 2}
            />
          </Entity>
        );
      }
    });
  }, [data, is3D, clickedDataset, heightFunction]);

  // do not render if there is no data
  const length = data?.length;
  if (length != undefined && length <= 0) return null;
  if (isPylonSearch && clickedDataset === null) return null;
  const hoverFile = data.find((item) => item.id === hoverId);
  return (
    <>
      {files}
      {is3D && hoverFile && (
        <img
          src={
            `${hoverFile?.id}` in ImageCache
              ? URL.createObjectURL(ImageCache[`${hoverFile.id}`])
              : "loading.png"
          }
          alt={hoverFile.id}
          className={classes.fileImagePreview}
        />
      )}
    </>
  );
};
