import React, { useEffect } from "react";
import {
  createBrowserRouter,
  FutureConfig,
  RouterProvider,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Home from "./screens/Home";
import { eonTheme } from "@ipen-portal/portal-components";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import LoginButton from "./components/auth/LoginButton";
import { addAPIProvider } from "@iconify/react";
import { ICON_CDN } from "./util/images";

// icon CDN server
addAPIProvider("", {
  resources: [ICON_CDN],
});

const router = createBrowserRouter([{ path: "/", Component: Home }]);

const AppContent = () => {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <RouterProvider router={router} fallbackElement={<Home />} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginButton />
      </UnauthenticatedTemplate>
    </div>
  );
};

type Props = {
  msalInstance: PublicClientApplication;
};

function App({ msalInstance }: Props) {
  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={eonTheme}>
        <AppContent />
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;
