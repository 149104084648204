import axios, { AxiosError } from "axios";
import { IPublicClientApplication } from "@azure/msal-browser";

type ApiProps = {
  instance: IPublicClientApplication;
  mounted: boolean;
  apiEndpoint: string;
  tenantAppId: string;
};

export async function fetchData(apiProps: ApiProps) {
  let token;
  let tokenError;
  const apiUrl = `${window.REACT_APP_API_GATEWAY_URL}` + apiProps.apiEndpoint;
  try {
    const response = await apiProps.instance.acquireTokenSilent({
      scopes: [`api://${apiProps.tenantAppId}/.default`],
    });
    token = response.accessToken;
  } catch (error) {
    tokenError = error;
  }

  if (tokenError) {
    return { tokenError: "Error acquiring access token" };
  }

  try {
    const apiResponse = await axios.request({
      url: apiUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (apiProps.mounted) {
      return { response: apiResponse.data };
    }
  } catch (error: unknown) {
    const axiosError = error as AxiosError;

    return {
      error:
        axiosError.response ||
        axiosError.request ||
        axiosError.message ||
        axiosError,
    };
  }
}
