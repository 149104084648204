import {
  Autocomplete,
  Box,
  CircularProgress,
  Fade,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useMemo,
} from "react";
import { datasetSortFn, string2Date } from "../../../util/utils";
import {
  ArrowBackRounded,
  Cancel,
  CheckCircle,
  FlightTakeoff,
  ThreeDRotation,
} from "@mui/icons-material";
import { HvPylon } from "../../../types/grid";
import { useStyles } from "../styles";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateRange } from "../MDPViewer";
import {
  Dataset,
  DatasetState,
  FileDetail,
  FilePage,
  MediaType,
  Solution,
} from "../../../openapi/api";

interface ResultsProps {
  isPylonSearch: boolean;
  selectedPylon: HvPylon | null;
  respData: any;
  setSelectedId: (id: string) => void;
  selectedDatasets: Dataset[];
  clickedDataset: Dataset | null;
  selectedId: string | null;
  setClickedDataset: (dataset: Dataset | null) => void;
  setHoverDatasetId: (id: string | null) => void;
  setHoverId: (id: string | null) => void;
  resultLoading: boolean;
  selectedFileState: string | null;
  setSelectedFileState: (level: string | null) => void;
  solutions: any;
  selectedSolution: Solution | null;
  setSelectedSolution: (solution: Solution | null) => void;
  selectedDatasetState: string | null;
  setSelectedDatasetState: (level: string | null) => void;
  dateRange: DateRange<Dayjs>;
  setDateRange: Dispatch<SetStateAction<DateRange<Dayjs>>>;
  resetDateRange: () => void;
}

export const getDummyImage = (fileName, size = "thumb") => {
  if (`${fileName}`.includes("1")) {
    return `DJI_0008${size == "thumb" ? "_thumb" : ""}.jpg`;
  }
  if (`${fileName}`.includes("2")) {
    return `DJI_0034${size == "thumb" ? "_thumb" : ""}.jpg`;
  }
  return `DJI_0032${size == "thumb" ? "_thumb" : ""}.jpg`;
};

export const FileIcon = ({
  type,
  props = {},
}: {
  type: MediaType | string;
  props?: Record<string, {}>;
}) => {
  switch (type) {
    case MediaType.PointCloud:
      return <ThreeDRotation color="secondary" {...props} />;
    case MediaType.DroneImage:
      return <FlightTakeoff color="secondary" {...props} />;
    default:
      return <FlightTakeoff color="secondary" {...props} />;
  }
};

export const Results = ({
  isPylonSearch,
  selectedPylon,
  respData,
  setSelectedId,
  selectedId,
  setHoverId,
  setHoverDatasetId,
  selectedDatasets,
  clickedDataset,
  setClickedDataset,
  resultLoading,
  selectedFileState,
  selectedDatasetState,
  setSelectedFileState,
  setSelectedDatasetState,
  solutions,
  selectedSolution,
  setSelectedSolution,
  dateRange,
  setDateRange,
}: ResultsProps) => {
  const removeClickedDataset = () => {
    setClickedDataset(null);
    setSelectedId("");
  };

  useEffect(() => {
    return () => {
      removeClickedDataset();
    };
  }, []);

  return (
    <Fade in>
      <Box
        height="100%"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        data-testid="results"
      >
        <Box display="flex" px={2} mt={0.5} gap={1}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: "Check-in", end: "Check-out" }}
          >
            <DatePicker
              label="Start date"
              format="YYYY/MM/DD"
              value={dateRange[0]}
              onChange={(newValue) => {
                setDateRange((range) => [newValue, range[1]]);
              }}
            />
            <DatePicker
              label="End date"
              format="YYYY/MM/DD"
              value={dateRange[1]}
              onChange={(newValue) => {
                setDateRange((range) => [range[0], newValue]);
              }}
            />
          </LocalizationProvider>
        </Box>
        <Box display="flex" gap={2} p={2}>
          {!clickedDataset && (
            // dataset state level
            <Autocomplete
              fullWidth
              id="datasetStateSelector"
              aria-label="Search dataset state selector"
              options={Object.keys(DatasetState)}
              value={selectedDatasetState}
              onChange={(
                _event: ChangeEvent<{}>,
                selectedValue: string | null,
              ) => {
                setSelectedDatasetState(selectedValue);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="State"
                    variant="standard"
                    autoComplete="off"
                  />
                );
              }}
            />
          )}
          {!clickedDataset && (
            <Autocomplete
              fullWidth
              id="solutionSelector"
              aria-label="Search solution selector"
              options={solutions?.items || []}
              getOptionLabel={(option: Solution) =>
                option.application.display_name
              }
              getOptionKey={(option: Solution) => option?.id || "n/a"}
              value={selectedSolution as Solution}
              onChange={(
                _event: SyntheticEvent<Element, Event>,
                value: Solution | null,
              ) => setSelectedSolution(value)}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Solution"
                    variant="standard"
                    autoComplete="off"
                  />
                );
              }}
            />
          )}
        </Box>

        {/* loading */}
        {resultLoading && (
          <Box
            display="flex"
            justifyContent="center"
            height={100}
            alignItems="center"
          >
            <CircularProgress size={24} />
          </Box>
        )}
        {/* file search type result */}
        {!resultLoading && (
          <Box
            height="100%"
            overflow="hidden"
            display="flex"
            flexDirection="column"
          >
            <Box
              display="flex"
              p={2}
              alignItems="center"
              minHeight="18px"
              flexShrink={0}
            >
              {clickedDataset ? (
                <>
                  <IconButton
                    edge="start"
                    onClick={removeClickedDataset}
                    size="small"
                    aria-label="go back to dataset list"
                    sx={{ padding: 0, mr: 1 }}
                  >
                    <ArrowBackRounded fontSize="small" />
                  </IconButton>
                  <Typography variant="body1">
                    Drone Images at {clickedDataset.name}
                  </Typography>
                </>
              ) : (
                <Typography variant="body1">
                  Dataset Results{" "}
                  {isPylonSearch &&
                    `for HV-Pylon ${selectedPylon?.attributes?.name}`}
                </Typography>
              )}
            </Box>
            {clickedDataset ? (
              <>
                <FilesList
                  respData={respData}
                  setSelectedId={setSelectedId}
                  selectedId={selectedId}
                  setHoverId={setHoverId}
                  filterFn={(item) => item.dataset_id === clickedDataset.id}
                />
              </>
            ) : (
              <DatasetList
                setClickedDataset={setClickedDataset}
                selectedDatasets={selectedDatasets}
                setHoverDatasetId={setHoverDatasetId}
              />
            )}
          </Box>
        )}
        {/* file search type result 
        {!resultLoading && !isPylonSearch && (
          <Box
            height="100%"
            overflow="hidden"
            display="flex"
            flexDirection="column"
          >
            <Typography variant="h6" p={2}>
              Dataset Result
            </Typography>
            <FilesList
              respData={respData}
              setSelectedId={setSelectedId}
              setHoverId={setHoverId}
            />
          </Box>
        )}
        */}
      </Box>
    </Fade>
  );
};

type DatasetListType = Pick<
  ResultsProps,
  "selectedDatasets" | "setClickedDataset" | "setHoverDatasetId"
>;

const DatasetList = ({
  selectedDatasets,
  setClickedDataset,
  setHoverDatasetId,
}: DatasetListType) => {
  const { classes } = useStyles();
  useEffect(() => {
    return () => {
      setHoverDatasetId(null);
    };
  }, []);
  return (
    <Box overflow="scroll">
      {selectedDatasets.length > 0 ? null : (
        <Typography variant="body1" px={2} color="error">
          No Result for selected criteria.
        </Typography>
      )}
      {selectedDatasets.sort(datasetSortFn).map((item) => (
        <Box
          key={item.id}
          className={classes.searchItem}
          p={2}
          onClick={() => {
            setClickedDataset(item);
          }}
          onMouseEnter={() => {
            setHoverDatasetId(item.id);
          }}
          onMouseLeave={() => {
            setHoverDatasetId(null);
          }}
        >
          <Box
            display="flex"
            gap={2}
            overflow="hidden"
            justifyContent="space-between"
            width={1}
          >
            <Box
              flexShrink={1}
              display="flex"
              flexDirection="column"
              overflow="hidden"
            >
              <Typography
                display="flex"
                alignItems="center"
                gap={1}
                fontSize={16}
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {item?.name}
              </Typography>
              <Typography
                variant="body1"
                color="gray"
                textOverflow="ellipsis"
                overflow="hidden"
                fontSize={13}
              >
                created: {string2Date(item.created_at)}
              </Typography>
              <Typography
                variant="body1"
                color="gray"
                textOverflow="ellipsis"
                overflow="hidden"
                fontSize={13}
              >
                {item.id}
              </Typography>
            </Box>
            <Box flexShrink={0}>
              {item.state === DatasetState.Finished ? (
                <CheckCircle color="secondary" />
              ) : (
                <Cancel color="error" />
              )}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

type FilesListComponentType = Pick<
  ResultsProps,
  "respData" | "setSelectedId" | "setHoverId" | "selectedId"
> & {
  filterFn?: (item: FileDetail) => boolean;
};

const FilesList = ({
  respData,
  setSelectedId,
  selectedId,
  setHoverId,
  filterFn = () => true,
}: FilesListComponentType) => {
  const { classes } = useStyles();

  const data = useMemo(() => {
    return (respData as FilePage)?.items?.filter(filterFn) || [];
  }, [respData, filterFn]);

  useEffect(() => {
    return () => {
      setHoverId(null);
    };
  }, []);

  useEffect(() => {
    // when user click on drone position on map
    // it will try to scroll to the correct list item in left result bar
    const id = `result-md-${selectedId}`;
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [selectedId]);

  return (
    <Box overflow="scroll">
      {data.length > 0 ? null : (
        <Typography variant="body1" px={2} color="error">
          No Result for selected criteria.
        </Typography>
      )}
      {data.map((item: FileDetail) => {
        const hasCoordinates = !!item?.geometry;
        return (
          <Box
            key={item.id}
            id={`result-md-${item.id}`}
            className={classes.searchItem}
            display="flex"
            justifyContent="space-between"
            bgcolor={item.id === selectedId ? "#dddddd" : ""}
            gap="1rem"
            p={2}
            onClick={() => {
              setSelectedId(item.id);
            }}
            onMouseEnter={() => {
              setHoverId(item.id);
            }}
            onMouseLeave={() => {
              setHoverId(null);
            }}
          >
            <Box
              display="flex"
              gap={2}
              overflow="hidden"
              justifyContent="space-between"
              width={1}
            >
              <Box
                flexShrink={1}
                display="flex"
                flexDirection="column"
                overflow="hidden"
              >
                <Typography
                  display="flex"
                  alignItems="center"
                  gap={1}
                  fontSize={16}
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  <FileIcon type={item.type} />
                  {item?.name ? item.name : "N/A"}
                </Typography>
                <Typography
                  variant="body1"
                  color="gray"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontSize={13}
                >
                  created: {string2Date(item.created_at)}
                </Typography>
                <Typography
                  variant="body1"
                  color="gray"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontSize={13}
                >
                  {item.id}
                </Typography>
                {/* <Typography
                variant="body1"
                color="gray"
                textOverflow="ellipsis"
                overflow="hidden"
                fontSize={13}
              >
                {item.dataset_id}
              </Typography> */}
                {!hasCoordinates && (
                  <Typography variant="caption" fontSize={14} color="red">
                    Geolocation coordinates are missing.
                  </Typography>
                )}
              </Box>
              {item.type === MediaType.DroneImage && (
                <Box flexShrink={0}>
                  <Box
                    width={100}
                    height={100}
                    flexShrink={0}
                    overflow="hidden"
                    borderRadius={2}
                  >
                    <img
                      src={getDummyImage(`${item.name}`)}
                      alt="pylon-image"
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
