import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { humanFileSize, string2Date } from "../../../util/utils";

import {
  CheckCircle,
  Close,
  CloudCircle,
  FlightTakeoff,
  Lock,
  Star,
  PhoneAndroid,
  Info,
  ChevronRight,
} from "@mui/icons-material";
import clsx from "clsx";
import { SidebarWidth, useStyles } from "../styles";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useApi } from "../../../hooks/useApi";
import { FileIcon } from "./Results";
import { FileDetail, FileState, MediaType } from "../../../openapi/api";

interface ResultsWindowProps {
  selectedObject: FileDetail;
  setSelectedId: (id: string | null) => void;
  selectedImageDetails: FileDetail | null;
}

export const ResultsWindow = ({
  selectedObject,
  setSelectedId,
  selectedImageDetails,
}: ResultsWindowProps) => {
  const { classes } = useStyles();
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(true);
  if (!selectedObject) return null;
  return (
    <Box className={classes.detailView}>
      {isDetailsOpen && (
        <IconButton
          sx={{
            zIndex: 1,
            position: "absolute",
            bgcolor: "#fff !important",
            right: `calc(${SidebarWidth} - 1.3rem)`,
            top: "50%",
            border: "1px solid #dddddd",
          }}
          onClick={() => {
            setIsDetailsOpen(false);
          }}
        >
          <ChevronRight />
        </IconButton>
      )}
      <IconButton
        sx={{
          zIndex: 1,
          position: "absolute",
          bgcolor: "#fff !important",
          right: isDetailsOpen ? `calc(${SidebarWidth} + 1rem)` : "3.5rem",
          top: 8,
        }}
        onClick={() => {
          setSelectedId(null);
        }}
      >
        <Close />
      </IconButton>
      <IconButton
        sx={{
          zIndex: 1,
          position: "absolute",
          bgcolor: "#fff !important",
          right: "0.5rem",
          top: 8,
        }}
        onClick={() => {
          setIsDetailsOpen((open) => !open);
        }}
      >
        <Info />
      </IconButton>
      <Box
        height={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={isDetailsOpen ? `calc(100% - ${SidebarWidth})` : "100%"}
      >
        {selectedObject.type == MediaType.DroneImage && (
          <>
            <Box
              position="absolute"
              display="flex"
              justifyContent="center"
              height={50}
              alignItems="center"
            >
              <CircularProgress size={24} />
            </Box>
            {selectedObject?.mime && (
              <ImageViewer
                selectedObject={selectedObject}
                isDetailsOpen={isDetailsOpen}
              />
            )}
          </>
        )}
        {selectedObject.type == MediaType.PointCloud && (
          <Box>
            <Typography>No preview available.</Typography>
          </Box>
        )}
      </Box>
      {isDetailsOpen && (
        <Box
          width={SidebarWidth}
          display="flex"
          flexDirection="column"
          borderLeft="1px solid #dddddd"
        >
          <Typography
            variant="h6"
            display="flex"
            alignItems="center"
            gap={1}
            sx={{ p: 2 }}
          >
            File Details
          </Typography>
          {/* <Box
            sx={{
              width: 1,
              height: "150px",
              background: "#e9e9e9",
            }}
            flexShrink={0}
          /> */}
          <Box p={2} display="flex" justifyContent="space-between">
            <Typography
              variant="h6"
              display="flex"
              alignItems="center"
              gap={1}
              sx={{ wordBreak: "break-word" }}
            >
              <FileIcon type={selectedObject.type} />
              {selectedObject?.name}
            </Typography>
            <Box display="flex" gap={1} alignItems="center">
              <CheckCircle color="secondary" />
              <img
                style={{
                  width: "70px",
                  height: "25px",
                  borderRadius: "2px",
                }}
                src="linia_logo.png"
              />
              {/* <Box
                sx={{
                  width: "70px",
                  height: "25px",
                  background: "#e9e9e9",
                  borderRadius: "2px",
                }}
              /> */}
            </Box>
          </Box>
          <Box
            className={classes.detailsInfo}
            display="flex"
            gap={1}
            px={2}
            flexDirection="column"
          >
            <Box display="flex" gap={1}>
              <CloudCircle />
              <Typography>{string2Date(selectedObject?.file_at)}</Typography>
            </Box>
            <Box display="flex" gap={1}>
              <PhoneAndroid />
              <Typography>{string2Date(selectedObject?.created_at)}</Typography>
            </Box>
            <Box display="flex" gap={1}>
              <Lock />
              <Typography>{humanFileSize(selectedObject?.size)}</Typography>
            </Box>
          </Box>
          {selectedObject.type == MediaType.DroneImage && (
            <DroneImageDetails
              selectedObject={selectedObject}
              selectedImageDetails={selectedImageDetails}
            />
          )}
          {selectedObject.type == MediaType.PointCloud && (
            <PointCloudDetails
              selectedObject={selectedObject}
              selectedImageDetails={selectedImageDetails}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

interface ImageViewerProps {
  selectedObject: FileDetail;
  isDetailsOpen: boolean;
}

// scope: until page refresh
// image cache to avoid loading massive images again and again
export const ImageCache: Record<string, Blob> = {};

const ImageViewer = ({ selectedObject, isDetailsOpen }: ImageViewerProps) => {
  const { getImage } = useApi();
  const [image, setImage] = useState<HTMLImageElement | undefined>(undefined);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  const [imageNaturalWidth, setImageNaturalWidth] = useState<number>(0);
  const [imageNaturalHeight, setImageNaturalHeight] = useState<number>(0);

  const imageScale = useMemo(() => {
    if (
      containerWidth === 0 ||
      containerHeight === 0 ||
      imageNaturalWidth === 0 ||
      imageNaturalHeight === 0
    )
      return 0;
    const scale = Math.min(
      containerWidth / imageNaturalWidth,
      containerHeight / imageNaturalHeight,
    );
    return scale;
  }, [containerWidth, containerHeight, imageNaturalWidth, imageNaturalHeight]);

  const handleResize = useCallback(() => {
    if (container !== null) {
      const rect = container.getBoundingClientRect();
      setContainerWidth(rect.width);
      setContainerHeight(rect.height);
    } else {
      setContainerWidth(0);
      setContainerHeight(0);
    }
  }, [container, isDetailsOpen]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize, isDetailsOpen, selectedObject]);

  const handleImageOnLoad = (image: HTMLImageElement) => {
    setImageNaturalWidth(image.naturalWidth);
    setImageNaturalHeight(image.naturalHeight);
    setImage(image);
  };

  useEffect(() => {
    const fileId = selectedObject.id;
    if (!fileId) return;
    setImage(undefined);

    const datasetImage = (blob: Blob) => {
      const image = new Image();
      image.onload = () => {
        handleImageOnLoad(image);
        ImageCache[fileId] = blob;
      };
      image.src = URL.createObjectURL(blob);
    };

    // cache
    if (fileId in ImageCache) {
      datasetImage(ImageCache[fileId]);
    } else {
      getImage(selectedObject)
        .then((resp) => {
          datasetImage(resp.data as unknown as Blob);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [selectedObject]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#dddddd",
      }}
      ref={(el: HTMLDivElement | null) => {
        if (el) {
          setContainer(el);
        }
      }}
    >
      {imageScale > 0 && (
        <TransformWrapper
          key={`${containerWidth}x${containerHeight}`}
          initialScale={imageScale}
          minScale={imageScale}
          maxScale={imageScale * 12}
          centerOnInit
        >
          <TransformComponent
            wrapperStyle={{
              width: "100%",
              height: "100%",
            }}
          >
            {image ? (
              <img alt="image-view" src={image.src} />
            ) : (
              <img alt="loading..." />
            )}
          </TransformComponent>
        </TransformWrapper>
      )}
    </div>
  );
};

type DroneImageDetailsProps = Pick<
  ResultsWindowProps,
  "selectedObject" | "selectedImageDetails"
>;

const DroneImageDetails = ({
  selectedObject,
  selectedImageDetails,
}: DroneImageDetailsProps) => {
  const { classes } = useStyles();
  return (
    <Box
      m={2}
      overflow="hidden"
      display="flex"
      flexDirection="column"
      borderTop={1}
      borderColor="lightgray"
    >
      {/* <TextField
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="search" edge="start">
                        <SearchOutlined />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Meta data"
                autoComplete="off"
              /> */}
      {selectedObject?.state !== FileState.MetadataFailed &&
        selectedImageDetails === null && (
          <Box
            display="flex"
            justifyContent="center"
            height={50}
            alignItems="center"
          >
            <CircularProgress size={24} />
          </Box>
        )}
      {selectedObject?.state !== FileState.MetadataFailed &&
        selectedImageDetails && (
          <Box
            className={clsx(classes.detailsInfo, classes.detailsInfoExtra)}
            mt={1}
            style={{ overflowY: "scroll", overflowX: "hidden" }}
          >
            <TableContainer>
              <Table size="small" className={classes.detailsTable}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography color="black">File Details</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell scope="row">Name</TableCell>
                    <TableCell>{selectedObject.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">File Id</TableCell>
                    <TableCell>{selectedObject.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Dataset Id</TableCell>
                    <TableCell>{selectedObject.dataset_id}</TableCell>
                  </TableRow>
                  {/* <TableRow>
                          <TableCell scope="row">Download Path</TableCell>
                          <TableCell>
                            <Link
                              target="_blank"
                              rel="noopener"
                              href={selectedObject.dlPath}
                              aria-label="Download link"
                            >
                              Download
                            </Link>
                          </TableCell>
                        </TableRow> */}
                  <TableRow>
                    <TableCell scope="row">Creation Date</TableCell>
                    <TableCell>{string2Date(selectedObject.file_at)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table size="small" className={classes.detailsTable}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography color="black">Drone Image Details</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(selectedImageDetails.drone_image || {})
                    .sort()
                    .filter((item) => ![""].includes(item))
                    .map((attribute) => {
                      if (!selectedImageDetails.drone_image) return "";
                      let value = selectedImageDetails.drone_image[attribute];
                      if (attribute === "imageSize") {
                        value = `${value.join(" x ")} (W x H)`;
                      }
                      if (value !== 0) {
                        value = value || "n/a";
                      }
                      return (
                        <TableRow
                          key={attribute}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell scope="row">{attribute}</TableCell>
                          <TableCell>{value}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
    </Box>
  );
};

type PointCloudDetailsProps = Pick<
  ResultsWindowProps,
  "selectedObject" | "selectedImageDetails"
>;
const PointCloudDetails = ({
  selectedObject,
  selectedImageDetails,
}: PointCloudDetailsProps) => {
  const { classes } = useStyles();
  return (
    <Box
      m={2}
      className={clsx(classes.detailsInfo, classes.detailsInfoExtra)}
      style={{ overflowY: "scroll", overflowX: "hidden" }}
      display="flex"
      flexDirection="column"
      borderTop={1}
      borderColor="lightgray"
    >
      <TableContainer className={classes.detailsTable}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography color="black">File Details</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell scope="row">Name</TableCell>
              <TableCell>{selectedObject.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">File Id</TableCell>
              <TableCell>{selectedObject.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Dataset Id</TableCell>
              <TableCell>{selectedObject.dataset_id}</TableCell>
            </TableRow>
            {/* <TableRow>
                  <TableCell scope="row">Download Path</TableCell>
                  <TableCell>
                    <Link
                      target="_blank"
                      rel="noopener"
                      href={selectedObject.dlPath}
                      aria-label="Download link"
                    >
                      Download
                    </Link>
                  </TableCell>
                </TableRow> */}
            <TableRow>
              <TableCell scope="row">Creation Date</TableCell>
              <TableCell>{string2Date(selectedObject.created_at)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer className={classes.detailsTable}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography color="black">Point Cloud Details</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedImageDetails !== null &&
              Object.keys(selectedImageDetails.point_cloud || {})
                .sort()
                .filter((item) => ![""].includes(item))
                .map((attribute) => {
                  if (!selectedImageDetails.point_cloud) return "";
                  let value = selectedImageDetails.point_cloud[attribute];
                  if (attribute === "imageSize") {
                    value = `${value.join(" x ")} (W x H)`;
                  }
                  return (
                    <TableRow
                      key={attribute}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell scope="row">{attribute}</TableCell>
                      <TableCell className={classes.detailsCell}>
                        {value || "n/a"}
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
